import * as React from "react"
import PageLayout from "../../components/page-layout"
import Navbar from "../../components/navbar"
import ExternalLink from "../../components/external-link"
import { Link } from "gatsby"

import "./style.css"

const WorkPage = () => {
  return (
    <PageLayout title="work | grac">
      <body id="work-page" className="page">
        <div className="center-container">
          <Navbar />

          <div className="page-block">
            <h1 className="page-title">
              <span style={{ color: "var(--pink)" }}>W</span>
              <span style={{ color: "var(--yellow)" }}>O</span>
              <span style={{ color: "var(--green)" }}>R</span>
              <span style={{ color: "var(--darkblue)" }}>K</span>
            </h1>

            <p>this page is a wip</p>
          </div>


          <div className="page-block">
            <p>
              <strong>GitHub</strong>:{" "}
              <ExternalLink href="https://github.com/gracejiang">
                gracejiang
              </ExternalLink>
              <br />
              <strong>LinkedIn</strong>:{" "}
              <ExternalLink href="https://linkedin.com/in/gracj">
                gracj
              </ExternalLink>
              <br />
            </p>
          </div>
        </div>
      </body>
    </PageLayout>
  )
}

export default WorkPage
